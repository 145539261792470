<template>
    <div>
        <div class="wdn-banner">
            <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
                <van-swipe-item v-for="(item,index) in bannerList" :key="index">
                    <img :src="item.src" alt="">
                </van-swipe-item>
            </van-swipe>
        </div>
        <div class="content-module content-module-1 ">
            <div class="tab-title">
                <p class="header1">新闻动态</p>
                <p class="header2">News</p>
            </div>
            <div class="tab-content">
                <div class="module1-top" @click="details(obj.schooList[0]?.id)">
                    <div class="module1-top-right">
                        <p class="title">{{obj.schooList[0]?.articleTitle}}</p>
                        <p class="abstract">{{obj.schooList[0]?.articleAbstract}}</p>
                        <p class="time">{{$dayjs(obj.schooList[0]?.publishTime).format('YYYY/MM/DD')}}</p>
                    </div>
                    <div class="module1-top-left">
                        <img :src="obj.schooList[0]?.articleCover" alt="">
                    </div>
                </div>
                <div class="module1-bottom">
                    <template v-for="(item,index) in obj.schooList.slice(1,4)" >
                        <div class="bottom-list" :key="index">
                            <router-link :to="{path:'/newcms/detail',query:{ articleId: item.id,}}">{{item.articleTitle}}</router-link>
                            <span class="time" >{{$dayjs(item.publishTime).format('YYYY/MM/DD')}}</span>
                        </div>
                    </template>
                </div>
                <div class="module-more" @click="seeMore('/xwdt')">查看更多</div>
            </div>
        </div>
        <div class="content-module content-module-2 ">
            <div class="tab-title">
                <p class="header1">研究成果</p>
                <p class="header2">Research Achievements</p>
            </div>
            <div class="tab-content">
                 <ul>
                    <li v-for="(item,index) in obj.chatList" :key="index">
                        <div class="pro-img" @click="details(item.id)">
                            <img :src="item.articleCover" alt="">
                            <p class="time" v-if="item.topType==1">{{item.articleAbstract}}</p>
                        </div>
                        <div class="pro-text">
                            <p class="chatTitle">{{item.articleTitle}}</p>
                        </div>
                    </li>
                </ul>
                <div class="module-more" @click="seeMore('/cgzs')">查看更多</div>
            </div>
        </div>
        <div class="content-module expert-module">
            <div class="tab-title">
                <p class="header1">专家团队</p>
                <p class="header2">Experts Team</p>
            </div>
            <div class="tab-content">
                <van-swipe :loop="false" :width="swipWidth" :show-indicators="false" autoplay>
                    <van-swipe-item v-for="(item,index) in expertList" :key="index">
                        <div class="expert-item">
                            <div class="expert-img">
                                <img :src="item.src"/>
                            </div>
                            <div class="expert-text">
                                <p class="expert-name">{{item.name}}</p>
                                <p class="expert-intro">{{item.intro}}</p>
                                <p class="expert-detail">{{item.detail}}</p>
                            </div>
                        </div>
                    </van-swipe-item>
                </van-swipe>
            </div>
        </div>
        <div class="content-module coo-module">
            <div class="tab-title">
                <p class="header1">合作伙伴</p>
                <p class="header2">Partnership</p>
            </div>
            <div class="tab-content">
                <template v-for="(item,index) in 8">
                    <img :src="'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/edu-itc-fe-h5/co'+(index+1)+'.png'" :key="index">
                </template>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'Home',
    data(){
        return{
            swipWidth:150,
            obj:{
                schooList:[],//新闻动态
                chatList:[]
            },
            bannerList:[
                {src:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/edu-itc-fe-h5/banner.png'},
                {src:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/edu-itc-fe-h5/banner2.png'},
                {src:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/edu-itc-fe-h5/banner3.png'}
            ],
            expertList:[
                {
                    name:'王端瑞',
                    src:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/edu-itc-fe-h5/leader1.png',
                    intro:'国际互联网教育研究院联席院长',
                    detail:'伟东云教育集团董事长、香港工商总会会长、中华海外联谊会理事、山东省慈善总会荣誉会长'
                },{
                    name:'祝智庭',
                    src:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/edu-itc-fe-h5/leader2.png',
                    intro:'国际互联网教育研究院联席院长',
                    detail:'华东师范大学终身教授、教育信息化系统工程研究中心主任、教育部教育信息化技术标准委员会主任'
                },{
                    name:'汤子海',
                    intro:'国际互联网教育研究院常务副院长',
                    src:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/edu-itc-fe-h5/leader3.png',
                    detail:'全国信息技术标准化技术委员会教育技术分技术委员会副秘书长，国家发展改革委PPP专家'
                },{
                    name:'沈岱',
                    src:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/edu-itc-fe-h5/leader4.png',
                    intro:'国际互联网教育研究院常务副院长',
                    detail:'DEMOS培训教育集团全球总裁，伟东云教育集团副总裁'
                },{
                    name:'郑莉',
                    src:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/edu-itc-fe-h5/leader5.png',
                    intro:'专家委员会专家',
                    detail:'清华大学计算机科学与技术系教授，国家级教学团队骨干，IS0/IEC JTCl SC36国际标准组织…'
                },{
                    name:'李玉顺',
                    intro:'专家委员会专家',
                    src:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/edu-itc-fe-h5/leader6.png',
                    detail:'北京师范大学教育学部教育技术学院副教授，数字学习与教育公共服务教育部工程研究中心…'
                },{
                    name:'钱冬明',
                    intro:'专家委员会专家',
                    src:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/edu-itc-fe-h5/leader7.png',
                    detail:'华东师范大学开放教育学院副教授、华东师大教育信息化系统工程研究中心常务副主任…'
                },
                {
                    name:'张福涛',
                    intro:'专家委员会专家',
                    src:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/edu-itc-fe-h5/leader8.png',
                    detail:'山东省昌乐一中常务副校长，先后被评为全国课改杰出校长、全国新课程实施先进个人、全国…'
                }
            ]
            
        }
    },
    methods:{
        details(data){
            this.$router.push({
                path:'/newcms/detail',
                query:{
                    articleId:data
                }
            })
        },
        seeMore(data){
            this.$router.push({
                path:data
            })
        },
        getArticleInfo(className,list,pageSize){
            const data ={
                sort:1,
                className:className,
                saasId:localStorage.getItem('saasId'),
                pageSize:pageSize,
                pageNum:1
            }
            this.$api.home.getArticleInfo(data).then((res)=>{
                if(res.success){
                    if(className=='成果展示'){
                        this.obj[list] = res.data.list.sort((a,b)=>{
                            return Date.parse(b.publishTime) - Date.parse(a.publishTime)
                        })
                    }else{
                        this.obj[list] = res.data.list;
                    }
                    
                }
            })
        }
    },
    created(){
        this.getArticleInfo('成果展示','chatList',4);
        this.getArticleInfo('新闻动态','schooList',4);
    },
    mounted() {
        this.swipWidth = document.body.clientWidth * 0.4 + 20;
    },
}
</script>
<style lang="less" scoped>
@import url('./index.less');
</style>

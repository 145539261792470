<template>
    <div class="school-footer">
        <div class="footer-top">
            <div class="footer-header">
                <p class="title1">国际互联网教育研究院</p>
                <p class="title2">International Research Institute of ICT-Based Education</p>
            </div>
            <div class="footer-top-left">
                <p class="title">联系我们</p>
                <p class="tel">地址：北京市西城区北三环中路23号燕莎盛世大厦5层</p>
                <p><a href="tel:15810818941">电话：15810818941</a></p>
                <p>邮箱：chenhy@wedon.com</p>
            </div>
        </div>
        <div class="footer-bottom">
            <p>京ICP备10026535号-38</p>
            <p>2022-2023 北京伟东凌鸿教育科技有限公司所有</p>
        </div>
    </div>
</template>
<script>
export default {
    name:'Footer'
}
</script>
<style lang="less" scoped>
.school-footer{
    background:#333;
    color:#A8A8A8;
    font-size: 18px;
    line-height: 22px;
    a{
        color:#A8A8A8;
    }
    .footer-header{
        margin-bottom:100px;
        color:#fff;
        .title1{
            font-size: 36px;
            line-height: 44px;
            margin-bottom:16px;
        }
        .title2{
            font-size:20px;
            line-height: 26px;
        }
    }
    .footer-top{
        padding:60px 50px;
        p{
            margin-bottom: 30px;
            padding-left: 25px;
        }
    }
    .footer-top-left{
        font-size: 24px;
        .title{
            font-size: 24px;
            color:#fff;
            margin-bottom:36px;
        }
    }
    .footer-bottom{
        font-size: 24px;
        border-top: 2px solid #5A5A5A;
        padding:60px 0 35px;
        margin:0 50px;
        p{
            padding-left:25px;
            margin-bottom:25px;
        }
    }
}
</style>


<template>
    <van-popup v-model="show"  position="left" >
      <div class="toggle-span">
        <span  @click="toggle()">
          <svg-icon icon-class="toggle-icon"></svg-icon>
        </span> 
      </div>
      <van-collapse v-model="activeNames" accordion>
        <van-collapse-item 
          :border="false"
          :title="item.title" 
          :name="item.id"
          :key="item.id" 
          v-for="item in menuList" 
          @click.native="open(item.path)"
          :titleClass="activeNames==item.id?'active':''"
          :class="activeNames==item.id?'active':''">
          <ul v-if="item.children">
            <li v-for="i in item.children" :key="i.id">
              <router-link :to="i.path">{{i.title}}</router-link>
            </li>
          </ul>
        </van-collapse-item>
      </van-collapse>
    </van-popup>
</template>

<script>
export default {
  name: 'SideBar',
  data() {
    return {
      activeNames:'0',
      show:false,
      menuList: [
        { title: '首页', path: '/',id:0 },
        { title: '研究院概况',id:1,
          children:[
            { parentTitle: 1, title: '研究院简介', path: '/yjyjj' },
            { parentTitle: 1, title: '研究院架构', path: '/yjyjg' },
            { parentTitle: 1, title: '研究院领导', path: '/yjyld' },
            { parentTitle: 1, title: '专家委员会', path: '/zjwyh' },
            { parentTitle: 1, title: '秘书处', path: '/msc' },
          ]
        },
        { title: '新闻动态',id:2,path:'/xwdt'},
        { title: '成果展示',id:3,path:'/cgzs'},
        { title: '乡村振兴',id:4,
          children:[
            { parentTitle: 4, title: '乡村振兴大赛', path: '/xczxds' },
            { parentTitle: 4, title: '专项精准扶贫', path: '/zxjzfp' },
          ]
        },
        { title: '产教融合',id:5,
          children:[
            { parentTitle: 5, title: '专业共建', path: '/zygj' },
            { parentTitle: 5, title: '短期实训', path: '/dqsx' },
          ]
        },
        { title: '加入我们',id:6,path:'/jrwm'},
      ]
    }
  },
  mounted() {
   
  },
  watch:{
    $route:{
      handler(){
        this.show = false;
          if(this.$route.path!=='/'){
          this.menuList.map((item)=>{
            if(item.children){
              item.children.map(e=>{
                if(this.$route.path==e.path){
                  this.activeNames = e.parentTitle;
                }
              })
            }
          })
        }
      }
    }
  },
  methods: {
    toggle(event){
      this.show = !this.show;
      event.stopPropagation();
    },
    open(path){
      if(path=='/'&& this.$route.path!='/'){
        this.$router.push({
          path:'/'
        })
      }else if(path=='/'){
        this.show = false;
      }else{
        this.$router.push({
          path:path
        })
      }
    }
  }
}
</script>

<style scoped lang="less">
.van-hairline--top-bottom::after{
  border:none;
}
.toggle-span{
  pointer-events: none;
  padding-left: 50px;
  display: block;
  height: 111px;
  display: flex;
  align-items: center;
  width:60px;
  .icon{
    font-size: 50px;
  }
}
.toggle-icon{
  margin-bottom: 65px;
  font-size: 30px;
}
.van-popup{
  width:222px;
  /deep/.van-icon{
    display: none;
  }
}
.van-popup--left{
  top:0;
  bottom:0;
  height: 100vh;
  transform: translateY(0);
}
  .van-cell{
    font-size: 15px;
    padding:17.5px  16px;
    /deep/.van-icon{
      display: none;
    }
  }
  /deep/.van-collapse{
    .van-cell{
      // padding-left: 25px;
    }
    &>.active{
      .van-cell{
        background: rgba(247, 247, 247, 1);
        .van-cell__title{
          color:rgba(36, 95, 255, 1);
          position: relative;
          font-weight: 600;
          &::after{
            position: absolute;
            width:4px;
            height: 13px;
            content: '';
            background: rgba(36, 95, 255, 1);
            left: -25px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
      .van-collapse-item__wrapper,.van-collapse-item__content{
        background: rgba(247, 247, 247, 1);
      }
      .van-collapse-item__content{
        padding-top:0;
        padding-bottom: 0;
      }
    }
  }
  /deep/.van-collapse-item__title{
    padding-left:25px;
    &>{
      /deep/.active{
        color:rgba(36, 95, 255, 1);
        position: relative;
        
      }
    }
  }
  ul{
    padding-top: 3.5PX;
    padding-bottom:17.5PX;
    li{
      padding-left: 21PX;
      position: relative;
      margin-top:42px;
      &:nth-child(1){
        margin-top: 0;
      }
      &.active{
        background: rgba(247, 247, 247, 1);
      }
      a{
        font-size: 26px;
        line-height: 30px;
        color:rgba(51, 51, 51, 1);
        &.active{
          color:rgba(36, 95, 255, 1);
        }
      }
      .router-link-active{
        color:rgba(36, 95, 255, 1);
      }
    }
  }
</style>

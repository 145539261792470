/**
 * @file
 * @author tanghao
 * @date 2019-06-19
 */

import Vue from 'vue';
import Vuex from 'vuex';
import app from './module/app';
import pages from './module/pages';
import api from './../api'
import dynamic from '@wd/lowcode-h5/plugin/store/index.js';

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        pageConfigs: [],
        facecount:0,
        findBindInfo: null,
        distributionId: null,
        idcardBefore: null,
        surpluscount:4,
        isOpen:false
    },
    mutations: {
        setPageConfigs(state, payload) {
            state.pageConfigs = payload;
        },
        setIsOpen(state, payload) {
            state.isOpen = payload;
        },
    },
    getters: {
        getPageConfigs(state) {
            return (module, pageName) => state.pageConfigs.find(
                page => page.moduleId === module && page.name === pageName,
            );
        },
        userInfo (state) {
            return state.userInfo
        }
    },
    modules: {
        app,
        dynamic,
        pages,
    },
});

export default store

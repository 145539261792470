let RouterArr = [];
let RouterArr2 = [];

const Routers = require.context('./../modules/', true, /\.router\.js$/)

Routers.keys().forEach(key => {
  RouterArr = RouterArr.concat(Routers(key).default)
})

export default [
  ...RouterArr,
]



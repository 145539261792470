import Vue from 'vue';
import Vant from 'vant';
import { Toast } from 'vant';
import {Dialog, Notify} from 'vant'
import 'babel-polyfill';
import 'vant/lib/index.css';
import Videojs from 'video.js';
import 'video.js/dist/video-js.css'
import App from './app.vue';
import router from './router';
import store from './store';
import api from './api';
import Templates from './libs/template';
import './assets/icons'
import './libs/rem';
import './styl/app.styl';
import dayjs from 'dayjs'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import SDK from '@wd/lowcode-h5' 





/**
 * @description 全局注册应用配置
 */
Vue.use(Vant);
Vue.use(Toast);
Vue.use(Dialog)
Vue.use(Notify)
Vue.use(Templates)

Vue.config.productionTip = process.env.NODE_ENV === 'development';
Vue.prototype.$api = api;
Vue.prototype.$dayjs = dayjs;
Vue.prototype.$eventBus = new Vue();
api.configuration.getConfigurationGetSaas().then(res=>{
    localStorage.setItem('saasId',res.data.solutionInfo.rid)
})
Promise.all([SDK.setLayoutToRouter(router,store)]).then(() => {
    new Vue({
        router, 
        store, 
        render: h => h(App), 
    }).$mount('#wdc-app')
}) 



import Vue from 'vue';
import Router from 'vue-router';
import api from './../api'
import routes from './routers';
import store from '../store/index';
import config from '../config/url';
import { Notify } from 'vant'

Vue.use(Router);
const router = new Router({
    mode: 'history',
    routes,
});

const originalPush = Router.prototype.push
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
let params = {
    url: config.confUrl,
};
let frontendConfig = null

router.beforeEach(async (to, from, next) => {
    if (to.query.distributionId) store.state.distributionId = to.query.distributionId
    !frontendConfig && await api.usercenter.getConfiguration({ params }).then(res => {
        if (res.data) {
            frontendConfig = res.data.frontendConfig.filter(item => {
                if (item.key === 'homepage') {
                    return true;
                }
            })
            sessionStorage.setItem('theme',res.data.theme)
            sessionStorage.setItem('frontendConfig',JSON.stringify(res.data.frontendConfig))
            if (res.data.pageTitle) {
                document.title = res.data.pageTitle;
                localStorage.setItem('pageTitle', res.data.pageTitle)
            }
        }
    })
    next();
});

router.afterEach(() => {
    window.scrollTo(0, 0);
});

export default router;

export default [
    {
        path: '/cms/list',
        name: 'cms_list',
        meta: { login:false },
        component: () => import(/* webpackChunkName: 'error' */ './page/list.vue'),
    },
    {
        path: '/cms/detail',
        name: 'cms_detail',
        meta: { login:false },
        component: () => import(/* webpackChunkName: 'error' */ './page/detail.vue'),
    },
    {
      path: '/cms/video',
      name: 'cms_video',
      meta: { login:false },
      component: () => import(/* webpackChunkName: 'error' */ './page/video.vue'),
    }
]
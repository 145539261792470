const urlName = '/cms/v1'
export default API => ({
    // 列表
    getArticleInfo(data) {
        return API({
            url: `${urlName}/article/getArticleInfo` ,
            method:  'post',
            data
        });
    },
})

import URL from '../../config/url';
const UrlName = '/modular/admin/v1'

export default API => ({
    getConfiguration({ params, data }) {
        return API({ url: `${UrlName}/configuration/`, params, data });
    },
    //业务端获取PageJson
    getPageJson(params = {}){
      params.domain = URL.confUrl;
      return API({ url: `${UrlName}/block/getPageConfig`, params, method: 'POST' });
    },

    /**
     *
     * 获取配置项
     */
    getConfigurationNew(data = {}) {
        const domain = URL.confUrl;
        return API({ url: `/modular/admin/v1/configuration/${domain}`, data });
    },

    // 获取配置信息
    configuration(data){
      return API({
          url:`/modular/admin/v1/configuration/${data}`,
          method:'get',
      })
    },
    // 
    getConfigurationGetSaas() {
        const domain = URL.confUrl;
        return API({ 
            url: `${'/modular/admin/v1'}/configurations/${domain}`
        });
    },
})

<!-- 
 * @description: 加入我们
 * @fileName: index.vue
 * @date: 2022-08-22 14:08:48
!-->
<template>
    <div class="joinUs">
        <div class="joinUs-header">
            <p>加入我们</p>
        </div>
       <div class="joinUs-content">
            <div class="joinUs-right">
                <van-form ref="submitForm" class="demo-formData">
                    <van-field label="姓名"
                        name="name"
                        v-model="formData.name"
                        placeholder="请输入您的真实姓名"
                        required
                        :rules="[{ required:true,message: '请输入姓名' }]"
                    >
                    </van-field>
                    <van-field
                        label="联系电话"
                        required
                        :rules="[{ required:true,validator: phoneValidator, message: '请输入正确内容' }]"
                        name="phone"
                        v-model="formData.phone"
                        maxlength=11
                        placeholder="请输入您的联系电话">
                    </van-field>
                    <van-field
                        label="联系邮箱"
                        name="email"
                        v-model="formData.email"
                        placeholder="请输入您的联系邮箱"
                        :rules="[{ validator: emailValidator, message: '请检查输入内容' }]">
                        <el-input></el-input>
                    </van-field>
                    <van-field label="所属公司" name="company" v-model="formData.company" placeholder="请输入您的所属公司">
                    </van-field>
                    <van-field label="留言" 
                        required type="textarea"
                        name="remark" 
                        v-model="formData.remark" 
                        placeholder="请输入您想说的话"
                        :rules="[{ required:true,message: '请输入您想说的话' }]"
                        maxlength=1200>
                    </van-field>
                    <div class="submitBox">
                        <van-button  block type="info" class="submitBtn" native-type="button" @click="onSubmit">提交</van-button>
                    </div>
                </van-form>
            </div>
            <div class="joinText">
                <p>
                    <svg-icon icon-class="contacts-book-line"></svg-icon>
                    <span>联系人：陈老师</span>
                </p>
                <p>
                    <svg-icon icon-class="phone-line"></svg-icon>
                    <a href="tel:15810818941">联系电话：15810818941</a>
                </p>
                <p>
                    <svg-icon icon-class="mail-line"></svg-icon>
                    <span>联系邮箱：chenhy@wedon.com</span>
                </p>
                <p>
                    <svg-icon icon-class="map-pin-line"></svg-icon>
                    <span>公司地址：北京市西城区北三环中路23号燕莎大厦5层</span>
                </p>
            </div>
       </div>
    </div>
</template>
<script>
const isPhone = /^1(?:3[0-9]|4[5-9]|5[0-9]|6[12456]|7[0-8]|8[0-9]|9[0-9])[0-9]{8}$/
const isEmail = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/
  export default {
    name:'JoinUs',
    data() {
      return {
        formData: {
          name: '',
          phone:'',
          company:'',
          email:'',
          remark: '',
          type:'国际互联网教育研究院'
        },
        submitDisable:false,
      };
    },
    mounted(){
    },
    methods: {
        onSubmit(formName) {
            if(this.submitDisable){
                return false
            }
            this.$refs.submitForm.validate().then((res) => {
                this.submitDisable =  true;
                 const data = {
                    ...this.formData
                }
                this.$api.joinUs.save(data).then((res) => {
                    if(res.data){
                        this.$toast.success('留言提交成功！');
                        this.formData = {};
                        this.formData.type = '国际互联网教育研究院'; 
                    }
                }).finally(()=>{
                    this.submitDisable = false;
                });
            },(res)=>{
            });
        },
        phoneValidator (value){
            return isPhone.test(value)
        },
        emailValidator(value){
            if(value){
                return isEmail.test(value)
            }else{
                return true
            }
            
        }
    }
  }
</script>
<style lang="less" scoped>
.joinUs{
    padding-top: 45px;
    .joinUs-header{
        font-size: 48px;
        line-height: 56px;
        text-align: center;
    }
    .joinUs-content{
        padding-left:50px;
        padding-right: 50px;
        padding-top: 80px;
    }
}
.joinText{
    padding-top: 40px;
    padding-bottom: 66px;
    border-top:2px solid #D0D8E7;
    padding-left:20px;
    padding-right:20px;
    p{
        font-size: 24px;
        line-height: 32px;
        color: rgba(51,51,51,0.851);
        margin-bottom: 34px;
        display: flex;
        .icon{
            margin-right: 12px;
            font-size: 32px;
        }
        a{
            color: rgba(51,51,51,0.851);
        }
    }
}
.joinUs-right{
    padding-left: 20px;
    padding-right:20px;
    padding-bottom:140px;
        ::placeholder { /* Firefox, Chrome, Opera */ 
        color: #D0D8E7;
    }
    :-ms-input-placeholder { /* Internet Explorer 10-11 */ 
        color: #D0D8E7; 
    }
    ::-ms-input-placeholder { /* Microsoft Edge */ 
        color: #D0D8E7; 
    } 
    /deep/.van-cell--required .van-field__label span{
        position: relative;
        &::before{
            position: absolute;
            right: -10px;
            color: #FA5151;
            font-size: 18px;
            content: '*';
        }
    }
    /deep/.van-cell--required::before{
        content:'';
    }
    /deep/.van-field__error-message{
        color:#FA5151;
    }
    /deep/.van-cell{
        flex-direction: column;
        padding:0;
        margin-bottom:18px;
        &::after{
            border:none;
        }
        .van-field__control{
            font-size: 15px;
            padding:10px 8px;
        }
        textarea{
            height:120px;
        }
        .van-field__body{
            position: relative;
            &::after{
                content: '';
                position: absolute;
                pointer-events: none;
                top: 0;
                left: 0;
                border: 1px solid #D0D8E7;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                width: 200%;
                height: 200%;
                -webkit-transform: scale(0.5);
                transform: scale(0.5);
                -webkit-transform-origin: left top;
                transform-origin: left top;
                border-radius: 2px;
                overflow: hidden;
            }
        }
        .van-field__label{
            margin-bottom: 8px;
            span{
                font-size: 18px;
            }
        }
    }
    .submitBox{
        padding-top:24px;
    }
    .submitBtn{
        height:80px;
        line-height: 80px;
        width:100%;
        background:#0256FF;
        padding:0 24px;
        border:none;
        outline: none;
        appearance: none;
        &::after{
            background: transparent;
            border-color: transparent;
        }
        span{
            font-size: 36px;
        }
    }
}
</style>

export default {
    state: {
        config: {},
    },
    getters: {
    },

    mutations: {
        setConfig(state, payload) {
            state.config = payload;
        },
    },

    actions: {
        setConfig({ commit, config }) {
            commit('setConfig', config);
        },
    },
};

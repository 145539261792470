<template>
  <div id="wdc-app" class="wdc-app industry">
    <header>
      <div class="in-header">
        <span @click="handleClick()">
          <svg-icon icon-class="toggle-icon" ></svg-icon>
        </span>
        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/edu-itc-fe-pc/Logo.png"/>
      </div>
    </header>
    <router-view />
    <SideBar ref="SideBar"/>
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer'
import { mapState } from "vuex";
import store from './store/index';
import URL from './config/url';
import SideBar from './components/Menu'
export default {
  data () {
    return {
      isMenu:false,
      bannerItems: [],
    };
  },
  components: {
    SideBar,
    Footer
  },
  created () {
    this.$api.configuration.getConfigurationGetSaas().then(res=>{
      localStorage.setItem('saasId',res.data.solutionInfo.rid)
  })
  },
  methods: {
    onClickLeft(){
      this.isMenu = true;
    },
    close(){
      this.isMenu = false;
    },
    gopage(name){
      this.$router.push({ name })
    },
    handleClick() {
      this.$refs.SideBar.show = true;
    },
  },
};
</script>

<style lang="stylus">
html,body,.wdc-app {
  height: 100%;
}
.industry{
  padding-top:111px;
}

.van-notify--danger {
    background-color: #ee0a24 !important;
}

.van-tabbar--fixed {
  padding-bottom: 0px;
  position: fixed !important;
  bottom: 0;
  left: 0;
}

.van-tabbar-item__text {
  text-align: center;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset;
  -webkit-text-fill-color: #333;
}
input[type=text]:focus, input[type=password]:focus, textarea:focus {
  -webkit-box-shadow: 0 0 0 1000px white inset;
}
header{
  position:fixed;
  z-index:9;
  top:0;
  left:0;
  right:0;
  background:#fff;
  span{
    position:absolute;
    z-index:99;
    top:50%;
    transform translateY(-50%);
  }
  .in-header{
    display flex;
    height 111px;
    align-items center;
    box-sizing:border-box;
    padding-left:50px;
    padding-right:50px;
    background:#F6F8FC;
  }
  .icon{
    color:#333;
    font-size:50px;
  }
  img{
    width:338px;
    height:60px;
    margin:0 auto;
  }
}
</style>

<template>
  <div v-if="allArticleData" class="articleDetailLayout">
    <div class="articleTop">
      <div v-if="allArticleData.articleCover"
           class="aritcleTopLeft fl">
        <img
          :src="allArticleData.articleCover"
        />
      </div>

      <div v-if="allArticleData" class="aritcleTopRight fl">
        <p class="aritcleTopRightTitle">
          {{ allArticleData.articleTitle }}
        </p>
        <!--          <p v-if="allArticleData&&allArticleData.articleTitle&&allArticleData.articleTitle.length>9" class="aritcleTopRightTitle">-->
        <!--              {{ String(allArticleData.articleTitle).slice(0,9)+'...' }}-->
        <!--          </p>-->

        <div class="aritcleTopRightTitleDetail">
          <span v-if="(showPublishFlag==0||showPublishFlag=='')">发表时间：{{allArticleData.publishTime|formatTime}}</span>

          <!--            <span v-if="(showPublishFlag==0||showPublishFlag=='')&&allArticleData.sort==0">发表时间：{{allArticleData.publishTime|formatTime}}</span>-->

          <!--            <span v-if="(showPublishFlag==0||showPublishFlag=='')&&allArticleData.sort==2">发表时间：{{allArticleData.customTime|formatTime}}</span>-->
          <span v-if="showReadFlag==0||showReadFlag==''" class="seeNum">
            {{ allArticleData.readNum || 0 }}
          </span>
        </div>
      </div>
    </div>

    <div class="articleCont" v-html="allArticleData.content"></div>

    <!-- 视频文章 -->
    <div
      class="article-video-h5"
      v-if="allArticleData.articleVideoUrl"
    >
      <video
        class="article-video-play"
        controls
        autoplay
        :src="allArticleData.articleVideoUrl">
      </video>
    </div>

    <div class="articleBottom">
      <div class="articleBottomLeft">
        <div v-if="allArticleData.copyrightType==1" class="article-source">
          <div class="oneItem">文章来源: {{allArticleData.source}}</div>
          <div class="oneItem">版权归原作者所有，如有侵权请联系我们删除</div>
        </div>

        <div  class="fujianItem" v-for="(item, index) in articleList" :key="index" v-show="item.name">
          <div class="fujianItemLeft">
            <p class="fujianTitle fl">附件：</p>
            <p class="yasuobaoTitle fl">
              <span>{{ item.name }}</span>
              <!--              <span>{{ allArticleData.enclosureSize }}MB</span>-->
            </p>
          </div>
          <div class="fujianItemRight fr">
            <a
              :href="item.url"
              class="fujianDownload"
              target="_blank"
            >预览</a
            >
          </div>
        </div>

        <div v-show="allArticleData.externalName" class="fujianItem friendLinkItem">
          <div class="fujianItemLeft">
            <p class="fujianTitle fl">相关链接：</p>
            <p class="yasuobaoTitle renliziyuankiaoshiLink fl">
              {{ allArticleData.externalName }}
            </p>
          </div>
          <div class="fujianItemRight fr">
            <a
              :href="allArticleData.externalUrl"
              class="lijiqianwang"
              target="_blank"
            >立即前往</a
            >
          </div>
        </div>

      </div>
    </div>





    <!--    <div class="articleBottom">-->
    <!--      <div class="articleBottomLeft">-->

    <!--        <div v-show="allArticleData.enclosureName" class="fujianItem">-->
    <!--          <div class="fujianItemLeft">-->
    <!--            <p class="fujianTitle fl">附件：</p>-->
    <!--            <p class="yasuobaoTitle fl">-->
    <!--              <span>{{ allArticleData.enclosureName }}</span>-->
    <!--              &lt;!&ndash;              <span>{{ allArticleData.enclosureSize }}MB</span>&ndash;&gt;-->
    <!--            </p>-->
    <!--          </div>-->
    <!--          <div class="fujianItemRight fr">-->
    <!--            <a-->
    <!--              :href="allArticleData.enclosureUrl"-->
    <!--              class="fujianDownload"-->
    <!--              target="_blank"-->
    <!--            >预览</a-->
    <!--            >-->
    <!--          </div>-->
    <!--        </div>-->

    <!--        <div v-show="allArticleData.externalName" class="fujianItem friendLinkItem">-->
    <!--          <div class="fujianItemLeft">-->
    <!--            <p class="fujianTitle fl">相关链接：</p>-->
    <!--            <p class="yasuobaoTitle renliziyuankiaoshiLink fl">-->
    <!--              {{ allArticleData.externalName }}-->
    <!--            </p>-->
    <!--          </div>-->
    <!--          <div class="fujianItemRight fr">-->
    <!--            <a-->
    <!--              :href="allArticleData.externalUrl"-->
    <!--              class="lijiqianwang"-->
    <!--              target="_blank"-->
    <!--            >立即前往</a-->
    <!--            >-->
    <!--          </div>-->
    <!--        </div>-->



    <!--      </div>-->
    <!--    </div>-->


  </div>
</template>
<script>
export default {
  name: "articleDetial",

  data() {
    return {
      articleId: "",
      allArticleData: null,
      showReadFlag:this.$route.query.showReadFlag?this.$route.query.showReadFlag:'',
      showPublishFlag:this.$route.query.showPublishFlag?this.$route.query.showPublishFlag:'',
      paging: {                //翻页
        params: {
          pageNum: 1,
          pageSize: 99999
        },
      },
      articleList: [] // 存储pdf数组
    };
  },
  filters: {
    formatTime(val) {
      return (
        val.split(" ")[0].split("-").join("/") +
        " " +
        val.split(" ")[1].split(":").slice(0, 2).join(":")
      );
    },
  },
  created() {
    this.getArctileDetail();
  },
  computed: {},
  mounted() {},

  beforeDestroy() {},

  methods: {
    getArctileDetail() {
      this.$api.news
        .getArticleDetailNew({
          articleId: this.$route.query.articleId,
          readTag:1,
          pageNum: this.paging.params.pageNum,
          pageSize: this.paging.params.pageSize,
        })
        .then(async (res) => {
          if (res.success) {
            this.allArticleData = res.data.list[0];
            if(!this.allArticleData.enclosureUrl){
              this.articleList = JSON.parse(res.data.list[0].enclosureJson)
            } else {
              this.articleList.push({name: this.allArticleData.enclosureName,url: this.allArticleData.enclosureUrl})
            }
          } else {
            this.$message.error(res.message);
          }
        });
    },
  },
};
</script>

<style scoped>
.article-source{
  width: 100%;
  text-align: left;
  margin: 24px 12px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  color: #999999;
  font: 400 12px/.5em PingFangSC-Regular, PingFang SC;
}
.oneItem{
  line-height: 40px;
}
.fujianItem{
  margin-bottom: 16px;
}
@import "../asset/articleDetail.css";
</style>
